import WebServiceRequest from '../Api/WebServiceRequest'

class MakeConnectionForProductFieldFeature extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('ProductFeatureProductField/CreateV2')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class RemoveConnectionForProductFieldFeature extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
  }

  setParams(data) {
    super.setTag(`ProductFeatureProductField/${data.id}`)

  }
}

export {
  MakeConnectionForProductFieldFeature,
  RemoveConnectionForProductFieldFeature

}
