<template>
  <b-overlay :show="showOverlay">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <b-modal
        id="modal-children"
        cancelTitle="Cancel"
        centered
        ok-title="Submit"
        size="lg"
        title="Add Field To Feature Children"
        @ok.prevent="createFieldChild"
    >
      <b-row>
        <b-col cols="12">
          <b-table
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="productFields"
              :sort-by.sync="sortBy"
              bordered
              class="position-relative"
              empty-text="Nothing found !"
              primary-key="id"
              responsive
              show-empty
              striped
          >

            <template #cell(productFieldDetails)="data">
              <span>{{ data.item.productFieldDetails[0].name }}</span>
            </template>
            <template #cell(operation)="data">
              <b-form-radio
                  v-model="connectionModel.productFieldId"
                  :value="data.item.productFieldId"
              >

              </b-form-radio>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                  class="d-flex align-items-center justify-content-center"
                  cols="12"
                  sm="12"
              >
                <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  cols="12"
                  sm="6"
              >
                <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalCount"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"

        title="Remove Field From Feature Children"
        @ok.prevent="deleteConnection"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this field from Feature Children ?</span>
        </div>
      </div>
    </b-modal>
    <validation-observer ref="updateProductFeature" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Field Features</h2>
        <b-form class="mt-2">
          <b-row v-if="featureDetail">
            <b-col class="" md="4">
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="Name"
                >

                  <b-form-input
                      id="data-edit-seoTitle"
                      ref="fieldName"
                      v-model="featureDetail.name"
                      :state="errors.length > 0 ? false:null"

                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Hint"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                    v-model="featureDetail.hint"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col v-for="(item,idx) in currencies" md="3">
                  <b-form-group
                      :label="`Price In ${item.name}`"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        :ref="`price${idx}`"
                        :value="getPriceValue(item) ? getPriceValue(item).priceValue : ''"
                        type="number"
                    />

                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>

            <b-col v-if="featureDetail &&featureDetail.sliderMaxValue" cols="12">
              <b-row>
                <b-col cols="3">
                  <b-form-group
                      label="Slider Min Value"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        ref="fieldName"
                        v-model="featureDetail.sliderMinValue"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                      label="Slider Max Value"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        ref="fieldName"
                        v-model="featureDetail.sliderMaxValue"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                      label="Slider Default Value"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        ref="fieldName"
                        v-model="featureDetail.sliderDefaultValue"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
            <b-col cols="12">
              <b-button
                  class=" "
                  variant="primary"
                  @click="updateProductFeature"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
    <b-card class="data-edit-wrapper">
      <h2> Feature Children</h2>
      <b-form class="mt-2">
        <b-row>
          <b-col class="my-1" cols="3" md="12">
            <b-button
                v-b-modal.modal-children
                class="btn-tour-skip mr-1"
                variant="primary"
            >

              <span class="mr-25 align-middle text-white">Add</span>

            </b-button>
          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns2"
                :items="featureChildren"
                :sort-by.sync="sortBy"
                bordered
                class="position-relative"

                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >

              <template #cell(productFieldDetialName)="data">
                <span>{{ data.item.productFieldDetialName[0] }}</span>
              </template>
              <template #cell(operation)="data">
                <div class="d-flex align-items-center  w-100">
                  <!--                                            <b-link-->
                  <!--                                                v-if=""-->
                  <!--                                                :to="`/apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${$route.query.productId}&productFieldDetailId=${fieldDetail.productFieldDetailId}&productFeatureId=${data.item.productFeatureId}`"-->
                  <!--                                            >-->
                  <!--                                              <feather-icon-->
                  <!--                                                  class="text-primary"-->
                  <!--                                                  icon="EditIcon"-->
                  <!--                                                  size="20"-->
                  <!--                                              />-->
                  <!--                                            </b-link>-->
                  <span v-b-modal.modal-delete class="cursor-pointer mx-1"
                        @click="setSelectedConnection(data.item)"
                  >
                                        <feather-icon
                                            class="text-danger"
                                            icon="TrashIcon"
                                            size="20"
                                        />
                                        </span>
                </div>
              </template>
            </b-table>

          </b-col>
          <b-col cols="12">
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                    class="d-flex align-items-center justify-content-center"
                    cols="12"
                    sm="12"
                >
                  <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
                </b-col>
                <!-- Pagination -->
                <b-col
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    cols="12"
                    sm="6"
                >
                  <b-pagination
                      v-model="currentPageChildren"
                      :per-page="perPageChildren"
                      :total-rows="totalCountChildren"
                      class="mb-0 mt-1 mt-sm-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import {
  GetFeatureDetail,
  GetProductFieldsByFeatureId,
  GetProductFieldsByProductId,
  UpdateProductFeature
} from '@/libs/Api/productField'
import { GetAllCurrencies } from '@/libs/Api/Currencies'
import {
  MakeConnectionForProductFieldFeature,
  RemoveConnectionForProductFieldFeature
} from '@/libs/Api/ProductFeatureConnection'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormRadio,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'edit-product-feature',
  title: 'edit product feature',
  data() {
    return {

      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      featureDetail: null,
      currencies: null,
      sortBy: '',
      totalCount: null,
      productFields: null,
      perPage: 10,
      currentPage: 1,
      totalCountChildren: null,
      perPageChildren: 10,
      currentPageChildren: 1,
      selectedFieldConnection: null,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: false
        },
        {
          text: `Manage Product Field`,
          href: `/apps/games/game-service-categories/game-service/products/product-field/manage-product-field?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}&productFieldDetailId=${this.$route.query.productFieldDetailId}`,
          active: false
        },
        {
          text: `manage product feature`,
          href: `/apps/games/game-service-categories/game-service/products/product-field/product-feature/create-product-feature?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}&productFieldDetailId=${this.$route.query.productFieldDetailId}`,
          active: true
        },
      ],

      myTableColumns: [
        {
          key: 'productFieldId',
          label: 'Field Id',
          sortable: true
        },
        {
          key: 'productFieldDetails',
          label: 'name',
          sortable: true
        },

        {
          key: 'operation',
          label: 'operation'
        },
      ],
      myTableColumns2: [
        {
          key: 'productFieldId',
          label: 'Field Id',
          sortable: true
        },
        {
          key: 'productFieldDetialName',
          label: 'name',
          sortable: true
        },

        {
          key: 'operation',
          label: 'operation'
        },
      ],
      connectionModel: {
        productFeatureId: this.$route.query.productFeatureId,
        productFieldId: 0,
      },
      featureChildren: null,
      updateFeaturePayload: {
        productFeatureId: this.$route.query.productFeatureId,
        productFieldDetailId: this.$route.query.productFieldDetailId,
        hint: '',
        name: '',
        updateProductFeaturePrices: [],
        sliderMaxValue: null,
        sliderMinValue: null,
        sliderDefaultValue: null
      }
    }
  },

  async created() {
    await Promise.all([
      this.getFeatureDetail(),
      this.getAllCurrencies(),
      this.getProductFieldsByProductId(),
      this.getFeatureChildren()
    ])
  },
  watch: {
    currentPage: function (val) {
      this.getProductFieldsByProductId()
    }
  },
  methods: {
    async deleteConnection() {
      let _this = this
      _this.showOverlay = true
      let removeConnectionForProductFieldFeature = new RemoveConnectionForProductFieldFeature(_this)
      removeConnectionForProductFieldFeature.setParams({ id: _this.selectedFieldConnection.productFeatureProductFieldId })
      await removeConnectionForProductFieldFeature.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `successfully deleted`,
          },
        })
        _this.getFeatureChildren()
        _this.$bvModal.hide('modal-delete')
        _this.selectedFieldConnection = null
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setSelectedConnection(item) {
      this.selectedFieldConnection = JSON.parse(JSON.stringify(item))
    },
    async getFeatureChildren() {
      let _this = this
      _this.showOverlay = true

      let getProductFieldsByFeatureId = new GetProductFieldsByFeatureId(_this)
      let data = {
        productFeatureId: _this.$route.query.productFeatureId,
      }
      getProductFieldsByFeatureId.setParams(data)
      await getProductFieldsByFeatureId.fetch(function (content) {
        _this.showOverlay = false
        _this.featureChildren = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getProductFieldsByProductId() {
      let _this = this
      _this.showOverlay = true

      let getProductFieldsByProductId = new GetProductFieldsByProductId(_this)
      let data = {
        productId: _this.$route.query.productId,
        pageNumber: _this.currentPage,
        count: _this.perPage
      }
      getProductFieldsByProductId.setParams(data)
      await getProductFieldsByProductId.fetch(function (content) {
        _this.showOverlay = false
        _this.productFields = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async createFieldChild() {
      let _this = this
      if (_this.connectionModel.productFieldId !== 0) {

        _this.showOverlay = true
        let makeConnectionForProductFieldFeature = new MakeConnectionForProductFieldFeature(_this)
        makeConnectionForProductFieldFeature.setParams(_this.connectionModel)
        await makeConnectionForProductFieldFeature.fetch(function (content) {
          _this.showOverlay = false
          console.log(content)
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.connectionModel = {
              productFeatureId: _this.$route.query.productFeatureId,
              productFieldId: 0,
            }
            _this.$bvModal.hide('modal-children')
            _this.getFeatureChildren()
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'please select a field for connection',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    getPriceValue(item) {
      let idx = this?.featureDetail?.productFeaturePrices.findIndex(e => e.currencyId === item.id)
      console.log(idx)
      if (idx > -1) {
        return this.featureDetail.productFeaturePrices[idx]
      } else {
        return null
      }

    },
    async getAllCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: 1,
        count: 20,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getFeatureDetail() {
      let _this = this
      _this.showOverlay = true
      let getFeatureDetail = new GetFeatureDetail(_this)
      let data = {
        productFeatureId: _this.$route.query.productFeatureId
      }
      getFeatureDetail.setParams(data)
      await getFeatureDetail.fetch(function (content) {
        _this.showOverlay = false
        _this.featureDetail = content.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    createPayload() {
      this.updateFeaturePayload.name = this.featureDetail.name
      this.updateFeaturePayload.hint = this.featureDetail.hint
      this.updateFeaturePayload.sliderDefaultValue = this.featureDetail.sliderDefaultValue
      this.updateFeaturePayload.sliderMinValue = this.featureDetail.sliderMinValue
      this.updateFeaturePayload.sliderMaxValue = this.featureDetail.sliderMaxValue
      let isValid = true
      for (let i = 0; i < this.currencies.length; i++) {
        if (!this.$refs[`price${i}`][0]?.$el.value) {
          isValid = false
        }
      }
      this.currencies.forEach((item, idx) => {
        if (isValid) {
          this.updateFeaturePayload.updateProductFeaturePrices.push({
            currencyId: item.id,
            productFeaturePricesId: this.getPriceValue(item) ? this.getPriceValue(item).productFeaturePriceId : 0,
            priceValue: this.$refs[`price${idx}`][0]?.$el.value
          })
        }
      })
      return this.updateFeaturePayload
    },
    async updateProductFeature() {
      let _this = this
      Helper.validateForm(_this, 'updateProductFeature', async () => {
        let data = _this.createPayload()
        _this.showOverlay = true
        let updateProductFeature = new UpdateProductFeature(_this)
        updateProductFeature.setParams(data)
        await updateProductFeature.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/games/game-service-categories/game-service/products/product-field/manage-product-field?gameId=${_this.$route.query.gameId}&gameServiceCategoryId=${_this.$route.query.gameServiceCategoryId}&gameServiceId=${_this.$route.query.gameServiceId}&productId=${_this.$route.query.productId}&productFieldDetailId=${_this.$route.query.productFieldDetailId}&productFeatureId=${_this.$route.query.productFeatureId}`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
